import Splide from "@splidejs/splide";

export default class PostsSlider {
    constructor() {
        this.everyProductSlider = [];
        this.postsSlider = document.querySelectorAll(".posts__slider");
        this.events();
    }

    events() {
        window.addEventListener("DOMContentLoaded", () => {
            if (this.postsSlider.length) {
                this.initializepostsSlider();
            }
        });
    }

    initializepostsSlider() {
        this.postsSlider.forEach((item) => {
            this.everyProductSlider.push(
                new Splide(item, {
                    pagination: false,
                    perPage: 4,
                    perMove: 1,
                    gap: 36,
                    arrows: "slider",
                    trimSpace: true,
                    breakpoints: {
                        1310: {
                            perPage: 3,
                        },
                        1024: {
                            perPage: 2,
                            // arrows: false,
                            gap: 32,
                        },
                        575: {
                            perPage: 1.3
                        },
                    },
                }).mount()
            );
        });
    }
}

new PostsSlider();